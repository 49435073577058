import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { useIntl, injectIntl } from "gatsby-plugin-intl";
import ServicesComp from '../components/servicesComp';
import ServiceForm from "../components/serviceForm";

import styled from 'styled-components';

const H1 = styled.h1`
    font-size: 30px;
    margin-top:25px;
}`;

const H3 = styled.h3`
    font-size: 20px;
    margin-top: 20px;
    margin-bottom:8px;
}`;

const TextJustified = styled.div`
    text-align: justify;
}`;

const P = styled.p`
    margin-top: 5px;
    color: black;
    font-size: inherit;
}`;

const ServiceRenovationYourProperty = ({ data }) => {
    const intl = useIntl();

    const myHeaderRef = React.createRef();
    
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, myHeaderRef.current.offsetTop);
        }, 100);
    }, [])

    return (
        <Layout>
            <SEO title={intl.formatMessage({ id: 'Service3Title' })} />

            <section>
                <ServicesComp intl={intl}></ServicesComp>

                <section className="page-section blog-page">
                    <div className="container">
                        <TextJustified className="row"  ref={myHeaderRef}>
                            <H1><strong>{intl.formatMessage({ id: 'Service3Title' })}</strong></H1>
                        </TextJustified>
                        <TextJustified className="row">
                            <H3>{intl.formatMessage({ id: 'service3t1' })}</H3>
                            <P>{intl.formatMessage({ id: 'service3p1' })}</P>

                            <H3>{intl.formatMessage({ id: 'service3t2' })}</H3>
                            <P>{intl.formatMessage({ id: 'service3p2' })}</P>

                            <H3>{intl.formatMessage({ id: 'service3t3' })}</H3>
                            <P>{intl.formatMessage({ id: 'service3p3' })}</P>
                        </TextJustified>

                        <br/>

                        <div className="col-lg-6">
                            <ServiceForm intl={intl} />
                        </div>
                    </div>
                </section>
            </section>
        </Layout>
    )
}

export default injectIntl(ServiceRenovationYourProperty)
export const query = graphql`
query {
    alertIcon: file(relativePath: { eq: "alert.png" }) {
        childImageSharp {
            fixed(width: 30) {
                ...GatsbyImageSharpFixed
            }
        }
    },
}
`